<template>
  <!-- 角色管理 -->
  <div class="container" style="margin-top: 10px;">

      <div class="dataOper">
        <!-- 查询 -->
        <div class="OperInput"> 
           <div class="head">
          <div class="search_box">
            <div class="search_main">
              <div class="status">
                <div>用户来源</div>
                <div>
                  <el-select
                    placeholder="选择用户来源"
                    v-model="queryinfo.channel"
                  >
                  <el-option value="" label="全部"></el-option>
                    <el-option
                      v-for="(item, index) in source"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div>会员等级</div>
                <div>
                  <el-select placeholder="普通会员" v-model="queryinfo.level">
                    <el-option value="" label="全部"></el-option>
                    <el-option
                      v-for="item in level"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div>注册时间</div>
                <div>
                  <el-date-picker
                    v-model="datatime"
                    type="daterange"
                    range-separator="至"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </div>
            </div>
            <br/>
            <div class="search_main">
              <div class="status">
                <div>账号</div>
                <div>
                  <el-input
                    placeholder="用户手机号"
                    v-model="queryinfo.mobile"
                  ></el-input>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div>用户名</div>
                <div>
                  <el-input
                    v-model="queryinfo.nickName"
                    placeholder="昵称或姓名"
                  ></el-input>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status1" style="display: flex">
                <div class="demandBtn" @click="search">
                  <img src="../../assets/images/search.png" alt="" /> 查询
                </div>
                <div class="resetBtn" @click="reset">
                  <img src="../../assets/images/reset.png" alt="" />重置
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 查询 -->
      </div>
    </div>
    <div style="height: 10px"></div>
    <div class="body">
      <!-- 列表 -->
      <div class="dataTable">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%;font-family: PingFangRoutine;"
          :header-cell-style="tableHeaderColor"
          :cell-style="{ color: '#2C2C2C', fontSize: '14px' }"
        >
          <el-table-column prop="id" label="ID" width="170" align="center">
          </el-table-column>
          <el-table-column
            prop="nickName"
            label="用户信息"
            width="250"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="注册时间"
            width="260"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="mobile"
            label="手机"
            width="150"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="channel"
            label="用户来源"
            width="170"
            align="center"
          >
           <template v-slot:default="scope">
                <div v-if=" scope.row.channel == 0">pc</div>
                <div v-if=" scope.row.channel == 1">APP</div>
                <div v-if=" scope.row.channel == 2">小程序</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="level"
            label="会员等级"
            width="180"
            align="center"
          >
            <template v-slot:default="scope">
                {{levelFilter(scope.row.level)}}
            </template>
          </el-table-column>
          <el-table-column
            prop=""
            label="订单数/金额"
            width="180"
            align="center"
          >
            <template v-slot:default="scope">
              <div>订单数：{{ scope.row.score }}</div>
              <div>消费金额：￥{{ scope.row.balance }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="forbidden"
            label="是否拉黑"
            align="center"
            width="150"
          >
            <template v-slot:default="scope">
              <el-switch
                v-model="scope.row.forbidden"
                active-color="rgb(64,158,255)"
                inactive-color="rgb(138,138,138)"
                @change="changecomposestatus(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column  label="操作" align="center" >
            <template #default="scope" >
              <div class="operating">
                <div class="examine"  @click="examine(scope.row.id)">
                  <i class="iconfont icon-chakan2" style="font-size: 18px;"></i>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 列表 -->
    </div>
    <div style="height: 10px"></div>
    <div class="footer">
      <!-- 分页 -->
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px;">确定</div>
      </div>
      <!-- 分页 -->
    </div>
  </div>
</template>
<script>
import {
  memberList,
  memberSource,
  memberLevel,
  memberForbidden,
} from "@/api/member";
import "../../font-style/font.css";
export default {
  data() {
    return {
      queryinfo: {
        level:"",
        nickName:"",
        mobile:"",
        startTime: "",
        currPage: "",
        endTime: "",
        level:"",
        channel:""
      },
      datatime: [],
      tableData: [],
      source: [],
      level: [],
      total: null,
      setting: [],
      grade:null
    };
  },
  activated() {
    this.list();
  },
  methods: {
    //会员列表
    list() {
      memberList(this.queryinfo).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total * 1;
      });
      //   获取会员来源;
      memberSource().then((res) => {
        this.source = res.data;
      });
      //获取用户等级
      memberLevel(this.queryinfo).then((res) => {
        this.level = res.data.list;
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.queryinfo.currPage = val;
      this.list();
    },
    // table
   tableHeaderColor({ rowIndex }) {
     if (rowIndex === 0) {
       return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px'";
     }
   },
    //搜索
    search() {
      this.queryinfo.startTime = this.datatime[0];
      this.queryinfo.endTime = this.datatime[1];
      this.queryinfo.currPage = ""
      this.total = 0
      this.list();
    },
    // 重置
    reset() {
         this.total=0
         this.queryinfo.channel="",
         this.queryinfo.level="",
         this.queryinfo.nickName="",
         this.queryinfo.mobile="",
         this.queryinfo.startTime = '';
         this.queryinfo.endTime = '';
         this.datatime=""
         this.queryinfo.currPage=""
         this.list()
    },
    //会员拉黑
    changecomposestatus(val) {
      memberForbidden({ id: val.id }).then((res) => {
        if (res.data !== true) {
          return;
        }
        this.$notify({
          title: val.forbidden === true ? "拉黑" : "移除",
          message: val.forbidden === true ? "已拉黑" : "已移除",
          type: "success",
        });
      });
    },
    //查看
    examine(id) {
      this.$router.push({
        path: "/member/Detail",
        query: {
          id: id,
        },
      });
    },
    levelFilter(value){
      var str = ''
      this.level.find(item=>{
        if(item.id==value){
          str= item.name
        }
      })
      return str
    },
  },

};
</script>
<style lang="less" scoped>
@import "../../assets/css/common.less";
.head {
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  .el-card__body {
    padding: 20px;
  }
}
.el-table .cell{
  padding-right: 0px;
}
.status>div:nth-child(1) {
  width: 57px;
  text-align: right;
}
.head{
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 20px;
}
</style>
